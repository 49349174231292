export const PEN_PACKET_START = 0xc0;
export const PEN_PACKET_END = 0xc1;

export const PEN_SERVICE_UUID_16 = 0x19f1;
export const PEN_CHARACTERISTICS_WRITE_UUID_16 = 0x2ba0;
export const PEN_CHARACTERISTICS_NOTIFICATION_UUID_16 = 0x2ba1;

export const PEN_SERVICE_UUID_128 = "4f99f138-9d53-5bfa-9e50-b147491afe68";
export const PEN_CHARACTERISTICS_WRITE_UUID_128 = "8bc8cc7d-88ca-56b0-af9a-9bf514d0d61a";
export const PEN_CHARACTERISTICS_NOTIFICATION_UUID_128 = "64cd86b1-2256-5aeb-9f04-2caf6c60ae57";
