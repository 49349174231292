import React from "react";


interface Props extends  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  docsList?: Array<any>,
  selectedClass ?: string,
}
const ListView = (props : Props)=>{

  return (<div>123123123123</div>);
}


export default ListView;